// $(function(){


// 	var $body = app.dom.$body,
// 		$createJsElement = $body.find('.images__createJs'),
// 		$textarea = $body.find('.data__textarea'),
// 		$logoCheckbox = $body.find('.data__logo__checkbox'),
// 		$uploader = $body.find('.uploader'),
// 		$preloader = $body.find('.preloader'),
// 		$canvases = $body.find('.images__canvas'),
// 		canvasImg = new Image(),
// 		$logo = $body.find('.data__logo img'),
// 		logoWidth = $logo.width(),
// 		logoHeight = $logo.height(),
// 		hasImage = false,
// 		hasLogo = false,
// 		requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame;

// 	function init($block) {
// 		var canvas = document.createElement('canvas');
// 		$block.append(canvas);
// 		canvas.width = $block.data('width');
// 		canvas.height = $block.data('height');
// 		var stage = new createjs.Stage(canvas);
// 		var container = new createjs.Container();
// 		stage.addChild(container);
// 		// var particle = new createjs.Shape();
// 		// particle.graphics.beginFill('#000000').drawRect(0, 0, 150, 150 );
// 		// particle.x = 100;
// 		var text = 'text';
// 		var $text = new createjs.Text(text, "bold 60px Graphik", "#000000");
// 		$text.x = 0;
// 		$text.textBaseline = "top";
// 		console.log(text);
// 		// container.addChild(particle);
// 		container.addChild($text);
// 		stage.update();
// 		var draw = {
// 			text: function () {
// 				console.log($textarea[0].value.toUpperCase());
// 				var text = $textarea[0].value.toUpperCase(),
// 					padding = 20,
// 					fontSize = 60,
// 					lineHeight = fontSize*0.72*1.4,
// 					fontOffset = fontSize*0.04,
// 					lines = 1,
// 					textWidth = 0,
// 					textHeight = 0;
// 				var rectSize = {
// 						width: function () {
// 							return textWidth + padding*2;
// 						},
// 						height: function () {
// 							if (text) {
// 								return lineHeight*lines + padding*2;
// 							}
// 						}
// 					};
// 				var pos = {
// 						x: 0,
// 						y: 0
// 					};
// 				var drawLines = function () {
// 						var textArr = text.split(/\r?\n+/);
// 						lines = textArr.length;
// 						textArr.forEach(function (item, i) {
// 							var x = pos.x+padding,
// 								y = pos.y+padding+lineHeight/2+fontOffset;
// 							context.textBaseline = 'middle';
// 							context.font = fontSize + 'px Graphik';
// 							context.fillStyle = '#000';
// 							context.fillText(item, x, y+lineHeight*i);
// 							if (context.measureText(item).width > textWidth) {
// 								textWidth = context.measureText(item).width;
// 							}
// 						});
// 					};
// 				var drawRect = function () {
// 						context.fillStyle = '#fff650';
// 						context.fillRect(
// 							pos.x, pos.y,
// 							rectSize.width(), rectSize.height()
// 						);
// 					};

// 				drawLines();
// 				context.globalCompositeOperation = 'destination-over';
// 				drawRect();

// 			},
// 			logo: function () {
// 				context.drawImage($logo[0], 0, 0, 161, 30);
// 			}

// 		};
// 	}
// 	init($createJsElement);


// 	$canvases.each(function () {
// 		var $canvas = $(this),
// 			canvas = $canvas[0],
// 			context = canvas.getContext('2d'),
// 			dataWidth = $canvas.data('width'),
// 			dataHeight = $canvas.data('height'),
// 			imgHeight = 396;

// 		var resizeCanvas = function () {
// 			canvas.width = dataWidth;
// 			canvas.height = dataHeight;
// 			if ($canvas.parent().width() < dataWidth) {
// 				$canvas.parent().css({
// 					'padding-top': dataHeight/dataWidth*100 + '%'
// 				});
// 			} else {
// 				$canvas.parent().css({
// 					'width': dataWidth,
// 					'height': dataHeight
// 				});
// 			}
// 		};
// 		// resizeCanvas();
// 		// app.dom.$window.on('resize.canvas', resizeCanvas);

// 		var draw = {
// 			text: function () {
// 				var text = $textarea[0].value.toUpperCase(),
// 					padding = 20,
// 					fontSize = 60,
// 					lineHeight = fontSize*0.72*1.4,
// 					fontOffset = fontSize*0.04,
// 					lines = 1,
// 					textWidth = 0,
// 					textHeight = 0;
// 				var rectSize = {
// 						width: function () {
// 							return textWidth + padding*2;
// 						},
// 						height: function () {
// 							if (text) {
// 								return lineHeight*lines + padding*2;
// 							}
// 						}
// 					};
// 				var pos = {
// 						x: 0,
// 						y: 0
// 					};
// 				var drawLines = function () {
// 						var textArr = text.split(/\r?\n+/);
// 						lines = textArr.length;
// 						textArr.forEach(function (item, i) {
// 							var x = pos.x+padding,
// 								y = pos.y+padding+lineHeight/2+fontOffset;
// 							context.textBaseline = 'middle';
// 							context.font = fontSize + 'px Graphik';
// 							context.fillStyle = '#000';
// 							context.fillText(item, x, y+lineHeight*i);
// 							if (context.measureText(item).width > textWidth) {
// 								textWidth = context.measureText(item).width;
// 							}
// 						});
// 					};
// 				var drawRect = function () {
// 						context.fillStyle = '#fff650';
// 						context.fillRect(
// 							pos.x, pos.y,
// 							rectSize.width(), rectSize.height()
// 						);
// 					};

// 				drawLines();
// 				context.globalCompositeOperation = 'destination-over';
// 				drawRect();

// 			},
// 			logo: function () {
// 				context.drawImage($logo[0], 0, 0, 161, 30);
// 			},
// 			image: function () {
// 				app.utils.drawImageCover(context, canvasImg, 0, 0, canvas.width, 396);
// 			},
// 			canvas: function () {
// 				context.clearRect(0, 0, canvas.width, canvas.height);
// 				draw.text();
// 				if (hasLogo) draw.logo();
// 				if (hasImage) draw.image();
// 				requestAnimationFrame(draw.canvas);
// 			}
// 		};

// 		// draw.canvas();
// 	});

// 	app.setImage = function(src = false){
// 		if (src) {
// 			canvasImg.src = src;
// 			hasImage = true;
// 		} else {
// 			hasImage = false;
// 		}
// 	}

// 	$logoCheckbox.change(function() {
// 		$(this).is(":checked") ? hasLogo = true : hasLogo = false;
// 	});
// });
