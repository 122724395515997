(function(utils, config){

	utils.delayRender = function(callback){
		return setTimeout(callback, config.renderDelay);
	};

	utils.delayDuration = function(callback){
		return setTimeout(callback, config.duration);
	};

	utils.prevent = function(e){
		e.preventDefault();
	};

	utils.raf = function(callback){
		var func = window.requestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			window.oRequestAnimationFrame ||
			window.msRequestAnimationFrame;
		if (func) {
			return func(callback);
		} else {
			return window.setTimeout(callback, 1000 / 60);
		}
	};

	utils.drawImageCover = function (ctx, img, x, y, w, h, offsetX, offsetY) {
		if (arguments.length === 2) {
		x = y = 0;
		w = ctx.canvas.width;
		h = ctx.canvas.height;
		}

		// default offset is center
		offsetX = offsetX ? offsetX : 0.5;
		offsetY = offsetY ? offsetY : 0.5;

		// keep bounds [0.0, 1.0]
		if (offsetX < 0) offsetX = 0;
		if (offsetY < 0) offsetY = 0;
		if (offsetX > 1) offsetX = 1;
		if (offsetY > 1) offsetY = 1;

		var iw = img.width,
		ih = img.height,
		r = Math.min(w / iw, h / ih),
		nw = iw * r,   /// new prop. width
		nh = ih * r,   /// new prop. height
		cx, cy, cw, ch, ar = 1;

		// decide which gap to fill
		if (nw < w) ar = w / nw;
		if (nh < h) ar = h / nh;
		nw *= ar;
		nh *= ar;

		// calc source rectangle
		cw = iw / (nw / w);
		ch = ih / (nh / h);

		cx = (iw - cw) * offsetX;
		cy = (ih - ch) * offsetY;

		// make sure source rectangle is valid
		if (cx < 0) cx = 0;
		if (cy < 0) cy = 0;
		if (cw > iw) cw = iw;
		if (ch > ih) ch = ih;

		// fill image in dest. rectangle
		ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
	};

	utils.getRandomArbitrary = function(min, max) {
		return Math.floor(Math.random() * (max - min) + min);
	};

})(app.utils, app.config);
