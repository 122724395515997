app.plugins.register('uploader', function () {
	var $body = app.dom.$body,
		$uploader = $body.find('.uploader'),
		$uploaderClose = $body.find('.uploader__close'),
		$uploaderImg = $body.find('.uploader__img'),
		$uploaderInput = $body.find('.uploader__input'),
		$img = $('<img />');

	$img.appendTo($uploaderImg);

	var readURL = function(input) {
		if (input.files && input.files[0]) {
			var reader = new FileReader();

			reader.onload = function (e) {
				var scr = e.target.result;
				$img.attr('src', scr);
				app.pikachus.forEach(function(pika){
					pika.setImage(scr);
				});
				$uploader.removeClass('uploader_loading');
				$uploader.addClass('uploader_loaded');
			}
			reader.onloadend = function (e) {
				$uploader.removeClass('uploader_loading');
				$uploaderInput.val('');
			}
			reader.readAsDataURL(input.files[0]);
		}
	};

	$uploaderInput.change(function(){
		$uploader.addClass('uploader_loading');
		readURL(this);
	});

	$uploaderClose.on('click', function () {
		app.pikachus.forEach(function(pika){
			pika.setImage(false);
		});
		$uploader.removeClass('uploader_loaded');
	});
});
