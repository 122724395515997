$(function(){
	var $root = app.dom.$root,
		$canvas = $root.find('.images__canvas'),
		$downloadLink = $root.find('.images__download'),
		$logoCheckbox = $root.find('.data__logo__checkbox'),
		$createJsElement = $root.find('.images__createJs'),
		$textarea = $root.find('.data__textarea'),
		$emoji = $root.find('.data__emoji'),
		isLogo = false;

	app.pikachus = [];
	$createJsElement.each(function () {
		app.pikachus.push(new PikachuJs($(this)));
	})
	$canvas.each(function () {
		var $self = $(this);
		if ($self.hasClass('images__canvas_float')) {
			app.pikachus.push(new FloatPikachu($self));
		} else {
			app.pikachus.push(new FixedPikachu($self));
		}
	});

	$textarea.on('input', function(){
		app.pikachus.forEach(function(pika){
			pika.setText($textarea.val());
		});
	});
	$emoji.each(function(index) {
		var input = $(this);
		input.on('input', function() {
			app.pikachus.forEach(function(pika){
				pika.setEmoji(input.val(), index);
			});
		});
	});

	$logoCheckbox.change(function() {
		var logo = $(this).val();
		if ($(this).is(":checked")) {
			if($logoCheckbox.not($(this)).is(":checked"));
			$logoCheckbox.not($(this))[0].checked = false;
			app.pikachus.forEach(function(pika){
				pika.setLogo(logo);
			});
		} else {
			app.pikachus.forEach(function(pika){
				pika.setLogo(false)
			});
		}
	});

	$downloadLink.each(function (i) {
		$(this).on('click', function(){
			app.pikachus[i].download();
		});
	});


	$root.plugins([
		'uploader',
		'sounds',
		'data'
	]);
});
