class PikachuJs {
	constructor($canvas){
		this.$canvas = $canvas;
		var isNarrow = (this.$canvas.data('type') == 'narrow');
		this.ratio = this.$canvas.data('ratio') ? this.$canvas.data('og') : 2;
		this.width = this.$canvas.data('width') * this.ratio;
		this.height = this.$canvas.data('height') * this.ratio;
		this.fontSize = isNarrow ? 32* this.ratio : 45 * this.ratio;
		this.fontSizeSm = isNarrow ? 28 * this.ratio : 36 * this.ratio;
		this.sizeRatio = this.width / 700;
		this.isAvatar = this.$canvas.data('name') === 'avatar';
		this.emojoSize = this.$canvas.data('emoji');
		this.isOg = this.$canvas.data('og');
		this.nameImage = this.$canvas.data('name');
		this.format = this.isOg ? 'png' : 'jpeg';
		this.textArr = [];
		this.$emoji = [];
		this.bigFontSize = this.isOg ? this.sizeRatio * 82 : this.sizeRatio * 60;
		this.textBoxPadding = this.sizeRatio * 24;
		this.textBoxOffset = this.sizeRatio * 24;
		this.textBoxBottom = 0;
		this.textHeight = 0;
		this.paddingCorrect = 0;
		this.offset = this.sizeRatio * 24;
		this.logoX = 14 * this.ratio;
		this.logoY = 10 * this.ratio;
		this.canvasImg = new Image();
		this.logoColor = app.dom.$body.find('.data__logo_color img');
		this.logoBlack = app.dom.$body.find('.data__logo_black img');
		this.emojiFields = app.dom.$body.find('.data__emoji');
		this.emoji = null;
		this.imagesEmoji=[];
		this.textWidth = 0;
		this.zoom = $canvas.parent().find('.zoomer');
		this.zoomWrapper = $canvas.parent().find('.zoomer-wrapper');
		this.zoomMinus = $canvas.parent().find('.zoomer-minus');
		this.zoomPlus = $canvas.parent().find('.zoomer-plus');
		this.initCreateJS();
		this.initZoomer();
	}

	initCreateJS() {
		this.canvas = this.$canvas[0];
		this.canvas.width = this.width;
		this.canvas.height = this.height;
		this.logoWidth = this.sizeRatio * 86;
		this.stage = new createjs.Stage(this.canvas);
		this.container = new createjs.Container();
		this.bg = new createjs.Shape();
		var color = this.isAvatar ? '#151515' : '#fff';
		if (this.hasLogo == 'color') {
			color = '#FFF45F'
		}
		this.bg.graphics.beginFill(color).drawRect(0, 0, this.width, this.height);

		this.stage.addChild(this.container);
		this.render();
	}

	initZoomer() {
		const self = this;
		this.zooming = noUiSlider.create(this.zoom[0], {
			start: 1,
			connect: true,
			range: {
				'min': 1,
				'max': 2
			}
		});
		this.zoomMinus.on('click', function (parms) {
			self.zooming.set(+self.zooming.get() - 0.05);

		});
		this.zoomPlus.on('click', function () {
			self.zooming.set(+self.zooming.get() + 0.05);

		});

	}

	update() {
		this.stage.update();
	}

	render() {
		this.container.removeAllChildren();
		this.container.addChild(this.bg);
		this.getSizes();
		if (!this.hasEmoji && this.hasImage && !this.isAvatar) this.drawImage();
		if (this.text && !this.isAvatar) this.drawText();
		if (this.hasLogo && !this.isAvatar) this.drawLogo();
		if (this.hasEmoji) this.drawEmoji();
		if (!this.hasImage) this.zoomWrapper.fadeOut();
		this.update();
 	}

	setText(text) {
		this.text = text;
		this.textArr = this.text.split(/\r?\n+/);
		this.posX = 0,
		this.posY = 0;
		this.render();
	}

	getTextSize() {
		var self = this,
			fontSizeCurrent;
			fontSizeCurrent = self.bigFontSize;
		this.fontOffset = fontSizeCurrent*0.04;
		this.fontConfig = '900 ' + fontSizeCurrent + 'px Graphik';
		this.addText();
		this.checkFontSize(fontSizeCurrent);
		var stopper = this.hasLogo ? (this.width - this.logoWidth - this.textBoxOffset - this.sizeRatio * 56) / this.width : 0.85;


		while (self.textWidth > self.width*stopper || self.textHeight > self.height - this.offset*2) {
			fontSizeCurrent--;
			this.checkFontSize (fontSizeCurrent)
		}
	}

	addText () {
		var self = this;
		this.$text = [];
		this.textArr.forEach(function (item, i) {
			self.$text.push(new createjs.Text());
			self.$text[i].font = self.fontConfig;
			self.$text[i].text = item;
			self.$text[i].color = "#151515";
			self.$text[i].textBaseline = "middle";
		});
	};

	checkFontSize (fontSizeCurrent) {
		var self = this;
		this.textWidth = 0;
		self.fontConfig = '900 ' + fontSizeCurrent + 'px Graphik';

		if (!this.hasImage) {
			self.lineHeight = fontSizeCurrent;
		} else {
			self.lineHeight = fontSizeCurrent*0.95;
		}
		this.textHeight = this.textArr.length * this.lineHeight;
		this.paddingCorrect = this.lineHeight - fontSizeCurrent*0.72;
		this.$text.forEach(function (item, i) {
			item.font = self.fontConfig;
			if (item.getMeasuredWidth() > self.textWidth) {
				self.textWidth = item.getMeasuredWidth();
			}
		});
	};

	getSizes() {
		this.getTextBoxPos();
		this.getImgPos();
	}

	getTextBoxPos() {
		this.getTextSize();
		this.textBoxWidth = this.width;

		this.textBoxHeight = this.text ? this.sizeRatio * 86 + ((this.textArr.length - 1) * this.lineHeight) : 0;
		this.textBoxBottom = (this.text) ? this.textBoxY + this.textBoxHeight : 0;
		this.textBoxX = 0;
		this.textBoxY = this.hasImage || this.hasEmoji ? 0 : this.height/2 - this.textBoxHeight/2;
	}

	getImgPos() {
		this.imgWidth = this.width;
		this.imgHeight = this.height - this.textBoxBottom;
	}

	drawText() {
		var self = this,
			fontSizeCurrent;
		var rect = new createjs.Shape();
		rect.graphics.beginFill('#FFF').drawRect(
			this.textBoxX, this.textBoxY,
			this.textBoxWidth, this.textBoxHeight
		);
		self.container.addChild(rect);
		var x = this.isOg ? 64 : this.sizeRatio * 24,
		y = this.isOg ? 164 + 58 : this.hasEmoji || this.hasImage ? this.sizeRatio * 43 : this.height/2 - (this.textArr.length * self.lineHeight/2);
		self.$text.forEach(function (item, i) {
			item.x = x;
			item.y = y + i*self.lineHeight;
			self.container.addChild(item);
		})
	}

	setImage(src = false) {
		var self = this;
		if (src) {
			this.canvasImg.src = src;
			this.hasImage = true;
			this.canvasImg.onload = function () {
				self.render();
			}
		} else {
			this.hasImage = false;
			self.render();
		}
	}

	drawImage() {
		this.zoomWrapper.fadeIn("fast");
		var self = this;
		var bitmap = new createjs.Bitmap(this.canvasImg);
		bitmap.x = 0;
		bitmap.y = this.textBoxHeight;
		var bitmapWidth = this.canvasImg.width;
		var bitmapHeight = this.canvasImg.height;
		var ratioX = this.imgWidth/bitmapWidth;
		var ratioY = this.imgHeight/bitmapHeight;
		var ratio = 1;
		if (ratioX > ratioY) {
			ratio = ratioX
		} else {
			ratio = ratioY
		}

		bitmap.scaleX = ratio;
		bitmap.scaleY = ratio;
		bitmap.regX = bitmapWidth/2;
		bitmap.x = this.imgWidth/2;
		bitmap.y = this.textArr.length > 0 ? this.sizeRatio * 86 + (this.textArr.length - 1) * this.lineHeight : 0;
		this.container.addChild(bitmap);
		var ofsetX;
		var ofsetY;
		var ratioSet = ratio;
		this.zoom[0].noUiSlider.on('update', function( values, handle ) {
			ratioSet = ratio*(+values);
			bitmap.scaleX = ratioSet;
			bitmap.scaleY = ratioSet;
			bitmap.x >= bitmapWidth*ratioSet/2 ? bitmap.x = bitmapWidth*ratioSet/2 : '';
			bitmap.x <= (self.width - bitmapWidth*ratioSet/2) ? bitmap.x = (self.width - bitmapWidth*ratioSet/2) : '';
			bitmap.y <= self.textBoxBottom + (self.imgHeight - bitmapHeight*ratioSet) ? bitmap.y = self.textBoxBottom + (self.imgHeight - bitmapHeight*ratioSet) : '';
			self.update();
		});
		bitmap.on("mousedown", function(evt) {
			ofsetX = evt.target.x - evt.stageX;
			ofsetY = evt.target.y - evt.stageY;
		});
		bitmap.on("pressmove", function(evt) {
			var posX =  (evt.stageX + ofsetX) >= bitmapWidth*ratioSet/2 ? bitmapWidth*ratioSet/2 :
						(evt.stageX + ofsetX) <= (self.width - bitmapWidth*ratioSet/2) ? (self.width - bitmapWidth*ratioSet/2) : (evt.stageX + ofsetX) ;
			var posY = (evt.stageY + ofsetY) >= (self.textBoxBottom) ? (self.textBoxBottom) :
						(evt.stageY + ofsetY) <= self.textBoxBottom + (self.imgHeight - bitmapHeight*ratioSet) ? self.textBoxBottom + (self.imgHeight - bitmapHeight*ratioSet) : (evt.stageY + ofsetY) ;
			evt.target.x = posX;
			evt.target.y = posY;
			self.update();
		});
	}

	setLogo(active = false) {
		if (active) {
			this.hasLogo = active;
		} else {
			this.hasLogo = false;
		}
		this.render();
	}

	drawLogo() {
		var logo = this.hasLogo == 'color' ? this.logoColor[0] : this.logoBlack[0]
		var bitmap = new createjs.Bitmap(logo);
		var bitmapWidth = logo.naturalWidth;
		var bitmapHeight = logo.naturalHeight;
		this.logoWidth = this.sizeRatio * 160;
		var ratioX = this.isOg ? 60/bitmapHeight : this.sizeRatio * 160/bitmapWidth;

		bitmap.scaleX = ratioX;
		bitmap.scaleY = ratioX;

		bitmap.x = this.isOg ? 64 : this.width - bitmapWidth * ratioX - this.sizeRatio * 24;
		bitmap.y = this.isOg ? 64 : this.sizeRatio * 24;
		this.container.addChild(bitmap);
	}

	setEmoji(emoji, index) {
		var self = this;
		var textImage = TextImage();
		var style = {
			font: 'px Graphik',
			align: 'center',
			size: 192,
			lineHeight: '1.5em'
		};

		if (emoji.length > 0) {
			self.emojiFields.eq(index)[0].value = emoji;
			self.emoji = emoji;
		} else {
			self.emojiFields.eq(index)[0].value = '';
			self.emoji = null;
		}
		if (self.emoji) {
			self.hasEmoji = true;
		} else {
			self.hasEmoji = false;
		}
		self.posX = 0,
		self.posY = 0;
		self.$emoji = null;
		this.imagesEmoji = TextImage(style).toImage(self.emoji);

		$(this.imagesEmoji).load(() => {
			self.$emoji = new createjs.Bitmap(this.imagesEmoji);
			self.render();
		});
	}

	drawEmoji() {
		var self = this;
		var rect = new createjs.Shape();
		var color = this.isAvatar ? '#151515' : '#fff';
		if (this.isAvatar && this.hasLogo == 'color') {
			color = '#FFF45F'
		}
		rect.graphics.beginFill(color).drawRect(
			0, 0,
			this.width, this.height
		);
		if (this.isAvatar) {
			self.container.addChild(rect);
		}
		var x = this.width / 2,
		y = this.textBoxHeight ? (this.height + this.textBoxHeight) / 2 - 12 : (this.height) / 2;
		var length = self.$emoji.length;
		var letterSpacing = self.width/10;
		var scale = self.emojoSize / 253 * self.ratio;
		var bitmapWidth = self.imagesEmoji.naturalWidth;
		var bitmapHeight = self.imagesEmoji.naturalHeight;
		self.$emoji.scaleY = scale;
		self.$emoji.scaleX = scale;
		if (this.isAvatar) {
			y = (this.height) / 2 + 11
		}
		self.$emoji.x = x - (bitmapWidth * scale)/2;
		self.$emoji.y = y - (bitmapHeight * scale)/2;
		self.container.addChild(self.$emoji);
	}
	download() {
		var a = document.createElement('a');
		a.href = this.canvas.toDataURL(`image/${this.format}`);
		a.download = `${this.nameImage}.${this.format}`;
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
		a.parentNode.removeChild(a);
	}
}

