app.plugins.register('sounds', function () {
	var $sounds = $(this).find('audio'),
		$pikachu = app.dom.$root.find('.pikachu'),
		i = 0, prev;
	$pikachu.on('click', function(e) {
		!i ? prev = $sounds.length-1 : prev = i-1;
		$sounds[prev].pause();
		$sounds[prev].currentTime = 0;
		$sounds[i].play();
		i++;
		if (i>$sounds.length-1) i = 0;
	});
});
